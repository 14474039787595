// ui.js
import { getHideIntroOverlay, setHideIntroOverlay } from './cookies.js';
import { getTodaysWorkout, doubleTodaysWorkout } from './workoutGenerator.js';
import { setWorkoutTimes } from './workoutTimes.js';

const todaysWorkout = getTodaysWorkout();

document.addEventListener('DOMContentLoaded', () => {
    const overlay = document.getElementById('intro-overlay');
    const closeButton = document.getElementById('close-intro');
    const checkbox = document.getElementById('hide-on-future-loads');
    
    // Show overlay if the cookie does not exist or is false
    if (!getHideIntroOverlay()) {
        overlay.style.display = 'flex';
    }

    // Handle close button click and set cookie if checkbox is checked
    closeButton.addEventListener('click', () => {
        overlay.style.display = 'none';
        if (checkbox.checked) {
            setHideIntroOverlay();
        }
    });

    // Show overlay when the logo is clicked
    document.querySelector('.logo').addEventListener('click', () => {
        overlay.style.display = 'flex';
    });

    // Show overlay when "HIIT Genius" link is clicked
    document.getElementById('hiit-genius-link').addEventListener('click', (e) => {
        e.preventDefault();
        overlay.style.display = 'flex';
    });

    document.getElementById('privacy-policy-link').addEventListener('click', (e) => {
        e.preventDefault();
        document.getElementById('privacy-policy-overlay').style.display = 'flex';
    });

    document.getElementById('close-privacy-policy').addEventListener('click', () => {
        document.getElementById('privacy-policy-overlay').style.display = 'none';
    });

    document.getElementById('terms-conditions-link').addEventListener('click', (e) => {
        e.preventDefault();
        document.getElementById('terms-conditions-overlay').style.display = 'flex';
    });

    document.getElementById('close-terms-conditions').addEventListener('click', () => {
        document.getElementById('terms-conditions-overlay').style.display = 'none';
    });

    // Initial orientation check
    handleOrientationChange();
});

// Function to check if it's a mobile device with a small screen (like iPhone)
function isSmallMobileScreen() {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    const screenIsSmall = window.innerWidth <= 800 && window.innerHeight <= 800; // Target small screens (like iPhones)
    return isMobile && screenIsSmall;
}

// Handle changes in screen orientation
function handleOrientationChange() {
    const landscapeWarning = document.getElementById('landscape-warning');
    
    if (isSmallMobileScreen()) {
        const isLandscape = window.innerWidth > window.innerHeight;
        landscapeWarning.style.display = isLandscape ? 'flex' : 'none';
    } else {
        landscapeWarning.style.display = 'none';
    }
}

// Listen for orientation changes
window.addEventListener('resize', handleOrientationChange);

// Function to show only the required view
export function toggleView(showId) {
    const views = ['workout-container', 'timer-container', 'success-container'];
    views.forEach(viewId => {
        document.getElementById(viewId).style.display = viewId === showId ? 'flex' : 'none';
    });
}

const workoutFormats = {
    '30:30': { exerciseTime: 30, breakTime: 30 },
    '40:20': { exerciseTime: 40, breakTime: 20 },
    '50:20': { exerciseTime: 50, breakTime: 20 },
    '30:15': { exerciseTime: 30, breakTime: 15 },
    '60:30': { exerciseTime: 60, breakTime: 30 }
};

// Apply workout format and start workout if valid
export function initializeWorkout(format) {
    const selectedFormat = workoutFormats[format];
    
    if (selectedFormat) {

    	if (format === '30:15') {
            doubleTodaysWorkout();
        }
        // Set the workout times based on the selected format
        setWorkoutTimes(selectedFormat.exerciseTime, selectedFormat.breakTime);

    } else {
        console.error('Invalid workout format selected');
    }
}

export function setExerciseText(content) {
    document.getElementById("exercise").textContent = content;
}

export function populateExerciseList() {
    const exerciseListDiv = document.getElementById("exerciseList");
    const ul = document.createElement("ul");

    todaysWorkout.forEach(exercise => {
        const li = document.createElement("li");

        // Create the exercise text span
        const exerciseText = document.createElement("span");
        exerciseText.textContent = exercise.name;
        exerciseText.style.flexGrow = "1"; // Allow text to take up available space

        // Create the link emoji
        const link = document.createElement("a");
        link.href = `https://www.google.com/search?q=${exercise.linkValue}+how+to`;
        link.target = "_blank";
        link.textContent = "🔗";
        link.className = "link-emoji"; // Use the CSS class for styling

        // Append the text and link to the list item
        li.appendChild(exerciseText);
        li.appendChild(link);
        ul.appendChild(li);
    });

    exerciseListDiv.appendChild(ul);
}

// Function to provide audio feedback by speaking text
export function speakText(text) {
    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = 'en-US';
    window.speechSynthesis.speak(speech);
}

export function updateTimerDisplayCoral(remainingSeconds, percentage) {
    updateTimerDisplay(remainingSeconds, percentage, '#FF6F61');
}

export function updateTimerDisplayYellow(remainingSeconds, percentage) {
    updateTimerDisplay(remainingSeconds, percentage, '#FFD700');
}

function updateTimerDisplay(remainingSeconds, percentage, color) {
    const overlay = document.getElementById("circle-overlay");
    overlay.style.background = `conic-gradient(${color} ${percentage}%, rgba(${hexToRgb(color)}, 0) ${percentage}%)`;
    document.getElementById("countdown").textContent = remainingSeconds;
}

function hexToRgb(hex) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    return `${r}, ${g}, ${b}`;
}

// Import audio files
const beepAudio = new URL('../audio/beep.mp3', import.meta.url);
const successAudio = new URL('../audio/success.mp3', import.meta.url);

let audioContext;

document.addEventListener('click', () => {
    if (!audioContext) {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        console.log("AudioContext created.");
    }
});

function playSound(soundType) {
    const soundFile = soundType === 'success' ? successAudio : beepAudio;

    // Fetch and decode audio
    fetch(soundFile)
        .then(response => {
            if (!response.ok) throw new Error('Network response was not ok');
            return response.arrayBuffer();
        })
        .then(data => audioContext.decodeAudioData(data))
        .then(buffer => {
            const source = audioContext.createBufferSource();
            source.buffer = buffer;
            source.connect(audioContext.destination);
            source.start(0);
            console.log(`${soundType.charAt(0).toUpperCase() + soundType.slice(1)} sound played successfully.`);
        })
        .catch(error => console.error('Failed to load or play sound:', error));
}

// Helper functions for consistency
export function playBeepSound() {
    playSound('beep');
}

export function playSuccessSound() {
    playSound('success');
}

export function updateUpNextText(nextText) {
    const upNextElement = document.getElementById('up-next-text');
    if (nextText) {
        upNextElement.textContent = nextText;
        upNextElement.style.display = 'block'; // Show the element
    } else {
        upNextElement.style.display = 'none'; // Hide the element
    }
}

export function activateShareButton(socialMediaPost) {
  const shareButton = document.getElementById('share-button');
  
  shareButton.addEventListener('click', async () => {
    try {
      // Always copy the socialMediaPost to clipboard first
      await navigator.clipboard.writeText(socialMediaPost);
      console.log('Content copied to clipboard.');

      if (navigator.share && isMobileDevice()) {
        const shareData = {
          text: socialMediaPost // Default message for sharing
        };

        try {
          // Show a message to the user instructing them about Facebook limitations
          alert('Content copied to clipboard!');

          // Proceed with the Web Share API
          await navigator.share(shareData);
        } catch (error) {
          console.log('Something went wrong sharing the content', error);
        }
      } else {
        // For desktop or unsupported browsers, just notify that the content was copied
        alert('Content copied to clipboard! You can now paste it anywhere.');
      }
    } catch (clipboardError) {
      console.log('Failed to copy to clipboard', clipboardError);
      alert('Failed to copy content. Please copy it manually.');
    }
  });

  function isMobileDevice() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
}

